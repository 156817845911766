import React from "react";

import { GenericReportingDropdowns } from "../containers/GenericReportingDropdowns";
import { Tabs } from "antd";
import KlaviyoAcquisition from "../containers/KlaviyoAcquisition";

export default function KlaviyoAcquisitionPage() {

    const tabs = [
        {
            label: 'Klaviyo Acquisition',
            key: 'Klaviyo Acquisition',
            children: [<KlaviyoAcquisition></KlaviyoAcquisition>]
        },
    ]

    return (
        <div>
            <div style={{
                'display': 'flex',
                'flexDirection': 'column',
                'justifyContent': 'center',
                'width': '98vw',
                'alignSelf': 'center',
            }}>
                <GenericReportingDropdowns></GenericReportingDropdowns>
                <Tabs style={{
                    'width': '90vw'
                }} items={tabs} ></Tabs>
            </div>
        </div>
    )
}