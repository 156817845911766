import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const bulkUploaderApi = createApi({
    reducerPath: 'bulkUploaderApi',
    baseQuery: baseQuery,
    tagTypes: ['BulkUploader', 'UploadJobs', 'BulkUploaderConfig'],
    endpoints: (builder) => ({
        getBulkUploaderClients: builder.query({
            query: ({
                fbApiToken
            }) => '/bulk_uploader/clients' + '?fb_api_token=' + fbApiToken,
        }),
        createUploadJob: builder.mutation({
            query: ({
                clientId,
                jobTitle,
            }) => ({
                url: '/bulk_uploader/upload_job' + "?selected_fb_account_id=" + clientId + "&job_title=" + jobTitle,
                method: 'PUT',
            }),
            invalidatesTags: ['UploadJobs']
        }),
        getUploadJobs: builder.query({
            query: ({
                clientId
            }) => '/bulk_uploader/upload_jobs' + '?account_id=' + clientId,
            providesTags: ['UploadJobs']
        }),
        deleteUploadJob: builder.mutation({
            query: ({
                clientId,
                jobTitle
            }) => ({
                url: '/bulk_uploader/upload_job' + '?account_id=' + clientId + '&job_title=' + jobTitle,
                method: 'DELETE',
            }),
            invalidatesTags: ['UploadJobs']
        }),
        getCampaigns: builder.query({
            query: ({
                fbApiToken,
                clientId
            }) => '/bulk_uploader/campaigns' + '?account_id=' + clientId + '&fb_api_token=' + fbApiToken,
        }),
        getAdSets: builder.query({
            query: ({
                fbApiToken,
                campaignId
            }) => '/bulk_uploader/ad_sets' + '?campaign_id=' + campaignId + '&fb_api_token=' + fbApiToken,
        }),
        getAds: builder.query({
            query: ({
                fbApiToken,
                adSetId
            }) => '/bulk_uploader/ads' + '?ad_set_id=' + adSetId + '&fb_api_token=' + fbApiToken,
        }),
        getNewGoogleSheet: builder.mutation({
            query: ({
                googleSheetsToken,
                fbApiToken
            }) => ({
                url: '/bulk_uploader/copy_bulk_upload_document' + '?google_sheets_token=' + googleSheetsToken + '&fb_api_token=' + fbApiToken,
                method: 'GET',
            }),
        }),
        uploadBulkUploadJobConfig: builder.mutation({
            query: ({
                accountId,
                jobTitle,
                campaignId,
                adSetId,
                adId,
                googleSheetUrl }

            ) => {
                return {
                    url: '/bulk_uploader/configure_upload_job' + 
                         '?account_id=' + accountId + 
                         '&job_title=' + jobTitle + 
                         '&campaign_id=' + campaignId + 
                         '&ad_set_id=' + adSetId + 
                         '&ad_id=' + adId + 
                         '&google_sheet_url=' + encodeURIComponent(googleSheetUrl),
                    method: 'PUT',
                    invalidatesTags: ['BulkUploaderConfig']
                }
            }
        }),
        getBulkUploadJobConfig: builder.query({
            query: ({
                clientId,
                jobTitle
            }) => '/bulk_uploader/upload_job_config' + '?account_id=' + clientId + '&job_title=' + jobTitle,
            providesTags: ['BulkUploaderConfig']
        }),
        runBulkUploadJob: builder.mutation({
            query: ({
                clientId,
                jobTitle,
                fbApiToken,
                googleSheetsToken
            }) => ({
                url: '/bulk_uploader/run_upload_job' + '?account_id=' + clientId + '&job_title=' + jobTitle + '&fb_api_token=' + fbApiToken + '&google_sheets_token=' + googleSheetsToken,
                method: 'POST',
            }),
        }),
        getHowLongFacebookTokenIsValid: builder.query({
            query: ({
                fbApiToken
            }) => '/bulk_uploader/get_fb_token_expiry_in_seconds' + '?fb_api_token=' + fbApiToken,
        }),
    })
});


export const {
    useGetHowLongFacebookTokenIsValidQuery,
    useRunBulkUploadJobMutation,
    useGetBulkUploadJobConfigQuery,
    useUploadBulkUploadJobConfigMutation,
    useGetNewGoogleSheetMutation,
    useGetAdsQuery,
    useGetAdSetsQuery,
    useGetCampaignsQuery,
    useDeleteUploadJobMutation,
    useGetBulkUploaderClientsQuery, 
    useCreateUploadJobMutation, 
    useGetUploadJobsQuery
 } = bulkUploaderApi;

export default bulkUploaderApi.reducer;