import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TableContainer from '../TableContainer';
import dayjs from 'dayjs';
import { DatePicker } from 'antd';
import { useGetCampaignPeriodOnPeriodTableQuery } from '../../redux/api/adsReporting';


export default function PeriodOnPeriodTable({
    platform,
    selectedCampaigns,
}) {

    const [compareStartDateStr, setCompareStartDateStr] = useState(null);
    const [compareEndDateStr, setCompareEndDateStr] = useState(null);

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const granularity = useSelector((state) => state.genericReporting.dateGranularity);

    useEffect(() => {
        const startDate = dayjs(selectedStartDate);
        const endDate = dayjs(selectedEndDate);

        setCompareStartDateStr(startDate.subtract(32, 'days').format('YYYY-MM-DD'));
        setCompareEndDateStr(endDate.subtract(31, 'days').format('YYYY-MM-DD'));

    }, [selectedStartDate, selectedEndDate]);

    return (
        <div style={{
            "height": "auto",
            "paddingTop": "2vh",
        }}>
            <div style={
                {
                    'marginLeft': '2vw',
                }
            }>
                <h3>Compare Date Range</h3>
                <DatePicker.RangePicker
                value={
                [dayjs(compareStartDateStr), dayjs(compareEndDateStr)]
                }
                onChange={(dates) => {
                    setCompareStartDateStr(dates[0].format('YYYY-MM-DD'));
                    setCompareEndDateStr(dates[1].format('YYYY-MM-DD'));
                    }
                }
                />
            </div>
            <TableContainer
                tooltipTitle='Campaign Period on Period Table'
                useApi={useGetCampaignPeriodOnPeriodTableQuery}
                apiParams={{
                    platform: platform,
                    client: selectedClient,
                    selectedCampaigns: selectedCampaigns,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    compareStartDate: compareStartDateStr,
                    compareEndDate: compareEndDateStr,
                    granularity: granularity,
                
                }}
            ></TableContainer>
        </div>
    );
}