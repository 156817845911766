import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedClient: "",
    startDate: "",
    endDate: ""
}

export const shopifyReportingSlice = createSlice({
    name: 'shopifyReporting',
    initialState,
    reducers: {
        setSelectedClient: (state, value) => {
            state.selectedClient = value.payload;
        },
        setStartAndEndDate: (state, value) => {
            
            
            state.startDate = value.payload[0];
            state.endDate = value.payload[1];
        }
    }
})

export const { setSelectedClient, setStartAndEndDate } = shopifyReportingSlice.actions

export default shopifyReportingSlice.reducer