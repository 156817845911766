import React from 'react';
import ExecutiveSummaryBoxMetrics from '../ExecutiveSummary/ExecutiveSummaryBoxMetrics';
import { useGetTableQuery } from '../../redux/api/whitelistedMediaComparison';
import TableContainer from '../TableContainer';
import { useSelector, useDispatch } from 'react-redux'; 
import { setWhitelistedMediaGranularityLevel } from '../../redux/store/genericReportingSlice';

const WhitelistedMediaCharts = (
    {
        platform
    }
) => {

    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedGranularityLevel = useSelector((state) => state.genericReporting.whitelistedMediaGranularityLevel);

    return (
        <div>
            <ExecutiveSummaryBoxMetrics isAmazon={false} isInfluencer={false} isOutreach={false} useOldPrefix={false} prefixStr={
                `whitelisted_media_comparison/${platform}`
            }></ExecutiveSummaryBoxMetrics>
            <div>
            <TableContainer
                    useApi={useGetTableQuery}
                    apiParams={
                        {
                            client: selectedClient,
                            startDate: selectedStartDate,
                            endDate: selectedEndDate,
                            platform: platform,
                            groupBy: selectedGranularityLevel
                        }
                    }></TableContainer>
            </div>
        </div>
    );
};

export default WhitelistedMediaCharts;