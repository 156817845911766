import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useSelector, useDispatch } from 'react-redux';
import { setJwtToken, oauthSlice } from '../redux/store/oauthSlice';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useInterval from 'react-useinterval';

export default function RequireAuth({ children }) {

    const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const validHd = process.env.REACT_APP_AUTH_VALID_HD

    const dispatch = useDispatch()

    const [cookies, setCookie, removeCookie] = useCookies(['jwt']);

    useEffect(() => {
        if (cookies.jwt) {
            const jwtToken = cookies.jwt
            // check if the token is still valid
            const jwtData = jwtDecode(jwtToken)
            const expires = jwtData.exp
            const hd = jwtData.hd
            var isCookieValid = true;
            // if token is expired remove the cookie
            if (Date.now() >= expires * 1000) {
                removeCookie('jwt')
                isCookieValid = false;
            }
            // if the token is not from charlieoscar.com remove it
            if (hd != validHd) {
                removeCookie('jwt')
                isCookieValid = false;
            }
            // if the cookie is invalid set the user to not authenticated and require auth
            if (!isCookieValid) {
                setIsUserAuthenticated(false)
                removeCookie('jwt')
            }
            // if it is valid set the redux value
            else {
                setIsUserAuthenticated(true)
                dispatch(setJwtToken(jwtToken))
            }
        }
    }, [cookies])

    // check if the cookie expired every 5 seconds
    useInterval(
        () => {
            if (cookies.jwt) {
                const jwtToken = cookies.jwt
                // check if the token is still valid
                const jwtData = jwtDecode(jwtToken)
                const expires = jwtData.exp
                const hd = jwtData.hd
                var isCookieValid = true;
                // if token is expired remove the cookie
                if (Date.now() >= expires * 1000) {
                    removeCookie('jwt')
                    isCookieValid = false;
                }
                // if the token is not from charlieoscar.com remove it
                if (hd != validHd) {
                    removeCookie('jwt')
                    isCookieValid = false;
                }
                // if the cookie is invalid set the user to not authenticated and require auth
                if (!isCookieValid) {
                    setIsUserAuthenticated(false)
                    removeCookie('jwt')
                }
            }
        },
        5000
    )

    return (
        <div>
            <GoogleOAuthProvider
                clientId={clientId}
            >
                <>{isUserAuthenticated ? children :
                    <div>
                        <div style={{
                            width: '60vw',
                            margin: 'auto',
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifySelf: 'center',
                        }}>
                        <h1>Log In To Charlie Oscar App</h1>
                        <GoogleLogin
                        onSuccess={credentialResponse => {
                            setCookie('jwt', credentialResponse.credential)
                                }}
                                theme='filled_black'
                                shape='pill'
                                />
                    </div>
                    </div>}</>
            </GoogleOAuthProvider>
        </div>
    )
}