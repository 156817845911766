import { Spin, Button } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { AgGridReact, createGrid } from 'ag-grid-react'; // React Data Grid Component
import './ag-grid-theme.css'; // importing the Data Grid theme
import TooltipContainer from "../components/UI/TooltipContainer";


export default function TableContainer({ useApi, apiParams, title, height=null, width='auto', tooltipTitle='WIP' }) {

    const [showTable, setShowTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);
    const [rowData, setRowData] = useState([]);

    const [rowCount, setRowCount] = useState(100);

    const [gridApi, setGridApi] = useState(null);

    useEffect(() => {

        if (rowData) {
            setRowCount(rowData.length);
        }

    }, [rowData]);

    const api = useApi(apiParams);

    useEffect(() => {
        
        
        
        
        if (api.status === 'fulfilled') {
            setShowTable(true);
            setIsLoading(false);
            setColumnDefs(api.data.columnDefs);
            setRowData(api.data.rowData);
            
        }
        else if (api.status === 'rejected') {
            setShowTable(false);
            setIsLoading(false);
        }
        else if (api.status === 'pending') {
            setShowTable(true);
            setIsLoading(true);
        }
        else {
            setShowTable(false);
            setIsLoading(false)
        }
    }
        , [api]);

    return (
        <>  {showTable ? <>
            {isLoading ? <div style={{
                'display': 'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'height': height != null ? height : `${(rowCount * 2.15) + 4}rem`,
                'width': width
            }}><Spin></Spin></div> :
                <div>
                    {title ? <h3>{title}</h3> : <></>}
                    <div
                        style={{
                            height: height != null ? height : `${(rowCount * 2.15) + 4}rem`,
                            width: width,
                            maxHeight: '120vh',
                         }} // the Data Grid will fill the size of the parent container
                    >
                        <TooltipContainer title={tooltipTitle} style={{
                            // align to right
                            'display': 'flex',
                            'justifyContent': 'flex-end',
                            'marginBottom': '0.5vh',
                            'marginRight': '0.5vw'
                    }}></TooltipContainer>
                        <AgGridReact
                            columnDefs={columnDefs} // the columns you want to display
                            rowData={rowData} // the data you want to display
                            onGridReady={(params) => {
                                setGridApi(params.api);
                            }}
                        />
                    </div>
                    <br></br>
                    <Button onClick={() => {
                        var csv = '';
                        csv += columnDefs.map(column => column.headerName).join(',') + '\n';
                        rowData.forEach(row => {
                            csv += columnDefs.map(column => row[column.headerName]).join(',') + '\n';
                        });
                        const a = document.createElement('a');
                        a.href = 'data:attachment/csv,' + csv;
                        a.target = '_blank';
                        a.download = 'data.csv';
                        document.body.appendChild(a);
                        a.click();
                    }
                    }
                    >Download as csv</Button>
                </div>
            }
        </> : <></>}
        </>
    );
}