const Tooltips = {
    'Account': 'The account you would like to access (e.g. EU, US, etc.)',
    'Date Range': 'The period during which the data is collected or analyzed',
    'Filter By Date Granularity': 'The level of detail for breaking down the data by time intervals - ranging from day, weekly, monthly, yearly and custom date ranges',
    'Change the Rolling Window (Days)': 'The  time frame for the trend analysis below.',
    'Spend': 'The total amount of money spent on marketing or advertising campaigns.',
    'New Customers': 'Customers who made a purchase for the first time.',
    'New Subscribers': 'Customers subscribing for the first time are classified as new subscribers. This includes individuals who may have previously made a one-time purchase but are initiating their first subscription. Reactivations of prior subscriptions do not fall under this category.',
    'AOV': 'Average Order Value - The average amount of money spent by customers per transaction',
    'Subs AOV': 'Subscribers Average Order Value - The average amount spent by subscribers per transaction.',
    'NCPA': 'New Cost Per Acquisiton - The cost associated with acquiring a new customer.',
    'CPNS': 'Cost Per New Subscriber -  The cost associated with acquiring a new subscriber',
    'Revenue': 'The total income generated from sales excluding cancellations and influencer orders',
    'ROAS': 'Return On Ad Spend - Measures the revenue generated for every £ spent on advertising.',
    'Total Spend vs 7D Rolling Total Spend': 'This graph compares the overall marketing spend against your custom rolling total spend window.',
    'New Customers vs NCPA': 'This graph shows the relationship between the number of new customers acquired and the cost per acquisition (NCPA).',
    'New Subscribers vs CPNS': 'This graph compares the number of new subscribers with the cost per new subscriber (CPNS).',
    'AOV Trend': 'This graph tracks the trend in Average Order Value (AOV) over time, comparing the differences between new AOV and Returning AOV',
    'Revenue Split by New and Returning': 'This graph tracks the trend in revenue over time, comparing the differences between new Revenue and returning Revenue',
    'WIP': 'Work In Progress - This tooltip is not complete yet... Please check back later.',
    'New vs. Returning Customers by Product Group': 'This graph shows the relationship between new and returning customers by custom product groups.',
    'Country': 'Filter data based on geographic location',
    'Device Category': 'Filter data by the type of device used, such as mobile, desktop, tablet or TV',
    'Source/Medium': 'Filter data by conversion source/medium',
    'Funneling Breakdown': 'Breakdown of your overall website traffic and conversion funnel',
    'Sessions by Date': 'Displays the number of user sessions over time, helping you understand traffic trends. This uses the session_start event from Google Analytics.',
    'Transactions by Date': 'Shows the number of completed transactions over time, indicating sales trends.',
    'Conversion Rate by Date': 'Tracks the percentage of sessions that result in a conversion over time, providing insight into the effectiveness of your marketing efforts.',
    'View Product Rate by Date': 'Displays the percentage of sessions where users viewed a product, helping you gauge product interest.',
    'Add to Cart Rate by Date': 'Shows the percentage of the users that viewed a product that resulted in users adding items to their cart, indicating purchase intent.',
    'Begin Checkout Rate by Date': 'Tracks the percentage of people who added a product to their cart vs. the ones that started the checkout process, showing intent to purchase.',
    'Transaction Rate by Date': 'Displays the percentage of people who began checkout that resulted in a completed transaction, reflecting the final conversion rate.',
    'Date Granularity': 'Filter data by time intervals (e.g., daily, weekly, monthly, quarterly or yearly)',
    "Multi Channel Metric": "Select a multi channel metric to compare against the GA metric, Custom built metrics such as Total Spend, Total Revenue, Tiktok Spend, Youtube Spend, Pmax Spend etc.",
    "GA Metric": "Google Analytics Event Metric, Such as Sessions, Transactions, View Product Events etc.",
    "Forward Indicators": "Forward Indicators are metrics that help you predict future performance. These metrics are used to forecast future performance and make data-driven decisions.",
    "LTR Vs. LTV": "Select LTR or LTV to view the Lifetime Revenue or Lifetime Value of your customers.",
    "Filter By": "Filter data by OTP, Subscriber or All Customers",
    "Select a Configuration": "Select a configuration to split your product categories by",
    "Lifetime Revenue": "The average revenue generated by a group of customers over the lifetime of their relationship with your company broken down by their first purchase month.",
    "Cohorted Retention": "Displays the percentage of customers who continue to do business with you over a given period of time broken down by their first purchase month.",
    "Lifetime Revenue By Type": "The average revenue generated by a group of customers over the lifetime of their relationship with your company broken down by their first purchase line item type.",
    "Select Campaigns": "Select the campaigns you would like to view",
    "Select Date Granularity": "Filter data by time intervals (e.g., daily, weekly, monthly, quarterly or yearly)",
    "Spend Breakdown by Campaign": "This graph shows the breakdown of spend by campaign",
    "Clicks vs CPC": "This graph shows the relationship between the number of clicks and the cost per click (CPC)",
    "Conversions vs CPA": "This graph shows the relationship between the number of conversions and the cost per acquisition (CPA)",
    "Multimetric Comparison": "Select a marketing metric to compare campaigns against each other",
    "Campaign Period on Period Table": "This table shows the period on period comparison for each campaign",
    "Settings": "Select a custom conversion goal for each campaign which can be visualized in paid media dashboards",
    "Daily Metrics Table": "This table shows the daily metrics for each campaign",
    "Media Spend vs. NCPA": "This graph shows the relationship between the media spend and the new cost per acquisition (NCPA) for each channel, It uses the COmpass multipliers to show up-to-date performance from our attribution model adjusted to the time period.",
    "Percentage Contribution by Channel": "This graph shows the percentage contribution of each channel to the total value attributed by COmpass relative to the spend for the time frame of the COmpass run. This chart is intended to show the relative value of each channel in the media mix and drive healthier decision making in Spend.",
    "Compass Multipliers": "This graph shows the COmpass attributed multipliers for CPA for each channel using the in-platform CPA and the COmpass attributed CPA. The multipliers in this chart are used to calculate the COmpass CPA variable in the media spend vs NCPA chart above.",
    "Klaviyo Acquisition": "This graph shows leads sent and the customers acquired from those leads using Klaviyo each month.",
    "Landing Page": "The first page a user visits on your website before the funneling process begins.",
    "Revenue Breakdown by Customer Type": <div>
        Daily revenue trends split between new and returning customers. The blue bars represent total revenue, while the teal and black lines track revenue contributions from new and returning customers, respectively.
        <br />
        Please note there may be a slight discrepancy vs Amazon Seller Central over the last 14 days for 2 reasons
        <br />
        - Revenue is exclusive of cancelled orders which are updated in the dashboard daily.
        <br />
        - For orders that are not yet finalized (pending), the revenue is estimated using the median item value. This may result in a slight discrepancy within the last 14 days, until these orders are fully processed (shipped, cancelled, etc.).
    </div>,
    "Orders Breakdown by Customer Type": <div>
        Daily order trends split between new and returning customers. The blue bars represent total orders, while the teal and black lines track order contributions from new and returning customers, respectively.
        <br />
        - Orders are exclusive of cancellations which are updated in the dashboard daily.
    </div>
        

    }

export default Tooltips;