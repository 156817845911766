import React from 'react';
import { Pagination } from 'antd';
import { useState } from 'react';
import { useGetArchiveRowCountQuery, useGetInfluencerPostsQuery } from '../../redux/api/genericReporting';
import { useSelector } from 'react-redux';
import { InfluencerPostCard } from '../../components/Influencer/InfluencerPostCard';
import { Spin } from 'antd';

const InfluencerContentPage = () => {

    const client = useSelector((state) => state.genericReporting.selectedClient);

    const [current, setCurrent] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const count = useGetArchiveRowCountQuery({
        client: client
    });

    const posts = useGetInfluencerPostsQuery({
        client: client,
        page: current,
        pageSize: pageSize
    });
    
    return (
        <div style={{ minHeight: '70vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'flexWrap': 'wrap',
                'justifyContent': 'center',
                'alignContent': 'center',
                'alignItems': 'center',
                'marginTop': 'auto'
            }}>
                {posts.isFetching == 'true' || posts.data == undefined ? <Spin></Spin> : 
                <div style={{
                'display': 'flex',
                'flexDirection': 'row',
                'flexWrap': 'wrap',
                'justifyContent': 'center',
                'alignContent': 'center',
                'alignItems': 'center',
                'marginTop': 'auto'
            }}>
                        {posts.data.map((post) => {
                            return <InfluencerPostCard post={post}></InfluencerPostCard>
                        })}
                </div>
                }
            </div>
            <br></br>
            <div style={{
                'justifyContent': 'center',
                'alignContent': 'center',
                'alignItems': 'center',
                'display': 'flex',
                'marginTop': 'auto'
            }}>
                <Pagination
                    defaultCurrent={current}
                    current={current}
                    onChange={setCurrent}
                    pageSize={pageSize}
                    onShowSizeChange={(current, size) => setPageSize(size)}
                    total={count.data}
                />
            </div>
        </div>
    );
};

export default InfluencerContentPage;