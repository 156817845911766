import React from "react";
import { useSelector } from "react-redux";
import TableContainer from "../TableContainer";
import PlotContainer from "../PlotContainer";
import { useGetConsiderationContributorsQuery, useGetShortMediumTermImpactQuery } from "../../redux/api/compassProject";
import '../containerStyles.css';

export default function ConsiderationContribution() {


    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const selectedDateGranularity = useSelector((state) => state.genericReporting.dateGranularity);
    const selectedDrivers = useSelector((state) => state.compass.drivers);

    return (
        <div>
            <div className="full-width-container">
                <PlotContainer useApi={useGetShortMediumTermImpactQuery} useApiArgs={{
                    client_name: selectedClient,
                    start_date: selectedStartDate,
                    end_date: selectedEndDate,
                    granularity: selectedDateGranularity,
                    drivers: selectedDrivers
                }} />
            </div>
            <div className="full-width-container">
            <PlotContainer useApi={useGetConsiderationContributorsQuery} useApiArgs={{
                client_name: selectedClient,
                start_date: selectedStartDate,
                end_date: selectedEndDate,
                granularity: selectedDateGranularity,
                drivers: selectedDrivers
                    }} />
            </div>
        </div>
  )
}