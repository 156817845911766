import React from 'react';
import { Select } from 'antd';
import { useState } from 'react';
import PlotContainer from '../PlotContainer';
import { useGetLtvVsCacQuery} from '../../redux/api/genericReporting';
import { useSelector } from 'react-redux';
import '../containerStyles.css';
import TooltipContainer from '../../components/UI/TooltipContainer';

const LtvVsCac = () => {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const [filterType, setFilterType] = useState('All');

    return (
        <div>
            <div className='flexible-container' style={{
                'display': 'flex',
                'flexDirection': 'row',
                'justifyContent': 'center',
                'width': '95vw',

            }}>
                <div>
                    <h2>Filter By <TooltipContainer title='Filter By'></TooltipContainer></h2>
                    <Select style={{
                        'width': '10vw'
                    }}
                        options={
                            [
                                { label: 'All', value: 'All' },
                                { label: 'OTP', value: 'OTP' },
                                { label: 'Subscription', value: 'Subscription' }
                            ]
                        }
                        onChange={
                            (value) => {
                                setFilterType(value);
                            }
                        }
                        value={filterType}
                    ></Select>
                </div>
            </div>
            <div className='flexible-container' style={{
                'width': '95vw',
                'height': '170vh',
            }}>
                <PlotContainer tooltipTitle='Lifetime Revenue' useApi={useGetLtvVsCacQuery} useApiArgs={{
                    client: selectedClient,
                    type: filterType
                }} />
            </div>
        </div>
    );
};

export default LtvVsCac;