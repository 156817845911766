import React from 'react';
import { Card, Modal } from 'antd';
import InfluencerContent from './InfluencerContent';
import {InstagramFilled, TikTokFilled} from '@ant-design/icons';

export const InfluencerPostCard = ({ post }) => {

    const creatorPlatform = post['provider'];
    const creatorUsername = post['creator_username'];
    const postTime = post['takenAt'];
    const postTimeDate = new Date(postTime);
    const likeCount = post['like_count'];
    const commentCount = post['comment_count'];
    const playCount = post['play_count'];
    const viewCount = post['view_count'];
    const platformNice = creatorPlatform == 'INSTAGRAM' ? 'Instagram' : 'TikTok';
    const type = post['type'];
    const caption = post['caption'];
    const engagementLastUpdated = new Date(post['engagement_last_updated_at']);

    const [isModalVisible, setIsModalVisible] = React.useState(false);

    var creatorAvatarUrl = undefined;
    if (creatorPlatform == 'INSTAGRAM') {
        creatorAvatarUrl = 'https://instagram.com/' + creatorUsername
    } else if (creatorPlatform == 'TIKTOK') {
        creatorAvatarUrl = 'https://tiktok.com/@' + creatorUsername
    }

    const platformIcon = creatorPlatform == 'INSTAGRAM' ? <InstagramFilled style={{
        'color': '#E1306C',
        'fontSize': '1.2rem',
        'backgroundColor': '#FFFFFF',
        'borderRadius': '20%',
    }}></InstagramFilled> : <TikTokFilled style={{
            'color': '#010101',
            'fontSize': '1.2rem',
            'backgroundColor': '#FFFFFF',
            'borderRadius': '20%',
    }}></TikTokFilled>;

    const getTimeAgo = (date) => {
        const now = new Date();
        const diff = now - date;
        const diffInHours = Math.floor(diff / (1000 * 60 * 60));
        const diffInDays = Math.floor(diffInHours / 24);

        if (diffInDays > 0) {
            return `${diffInDays} day${diffInDays > 1 ? 's' : ''} ago`;
        } else {
            return `${diffInHours} hour${diffInHours > 1 ? 's' : ''} ago`;
        }
    };

    return (
        <div style={{
            'marginLeft': '1vw',
        }}>
            <Card
                extra={
                    <div style={
                        {
                            fontSize: '0.8rem',
                        }
                    }>
                        <a onClick={
                            () => { setIsModalVisible(true) }
                        }>View Details</a>
                    </div>
                }
                title={
                <div style={{
                    'marginTop': '1.5vh',
                    'display': 'flex',
                    'justifyContent': 'left',
                    'alignContent': 'left',
                    'alignItems': 'left',
                }}>
                    <div style={{
                        'display': 'flex',
                        'flexDirection': 'column',
                        'marginTop': '0',
                            color: 'white',
                        'fontSize': '0.8rem',
                    }}>
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row',
                            'justifyContent': 'center',
                            'alignContent': 'center',
                            'alignItems': 'center',
                            'marginBottom': '0.5rem',
                            'marginTop': '0',
                            'alignSelf': 'top'
                        }}>
                            <a style={{
                                color: 'white',
                                'display': 'flex',
                                'flexDirection': 'row',
                                'justifyContent': 'center',
                                'alignContent': 'center',
                                'alignItems': 'center',
                                'marginBottom': '0.5rem',
                                'marginTop': '0',
                                'alignSelf': 'top',
                                'position': 'relative',
                                }} href={creatorAvatarUrl}>
                                <div style={{
                                    'position': 'absolute',
                                    'right': '-0.2vw',
                                    'bottom': '-0.48vw'
                                }}>
                                    {platformIcon}
                                </div>
                                <img src={post['creator_avatar_url']} style={{
                                    'width': '2.5vw',
                                    'height': '2.5vw',
                                    'borderRadius': '5px',
                                    }}></img>
                                </a>
                                <div style={{
                                    'marginLeft': '0.4vw',
                                }}>{post['creator_username']}</div>
                        </div>
                        <>{post['creator_follower_count'].toLocaleString()} Followers</>
                    </div>
                </div>
            }
                style={{
                'width': '20vw',
                'margin': '0.5rem',
                'borderRadius': '10px',
            }}>
                <InfluencerContent contents={post.contents}></InfluencerContent>
                <div style={{
                    'justifyContent': 'center',
                    'alignContent': 'center',
                    'display': 'flex',
                    'marginTop': '1.5vh',
                    'marginBottom': '0',
                    'alignSelf': 'bottom',
                }}>
                    <>
                        Posted {getTimeAgo(postTimeDate)}
                    </>
                    <div></div>
                </div>
            </Card>
            <Modal open={isModalVisible} onOk={
                () => { setIsModalVisible(false) }
            }
                okText='Close'
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <div>
                    {
                        platformNice ? <div>
                            <b>Platform:</b> {platformNice
                            }</div> : <div></div>
                    }
                    {
                        type ? <div>
                            <b>Post Type:</b> {type
                            }</div> : <div></div>
                    }
                    {
                        caption ? <div>
                            <b>Caption:</b> {caption
                            }</div> : <div></div>
                    }
                    {likeCount ? <div>
                        <b>Likes:</b> {likeCount.toLocaleString()
                        }</div> : <div></div>
                    }
                    {
                        commentCount ? <div>
                            <b>Comments:</b> {commentCount.toLocaleString()
                            }</div> : <div></div>
                    }
                    {
                        playCount ? <div>
                            <b>Plays:</b> {playCount.toLocaleString()
                            }</div> : <div></div>
                    }
                    {
                        viewCount ? <div>
                            <b>Views:</b> {viewCount.toLocaleString()
                            }</div> : <div></div>
                    }
                    {
                        engagementLastUpdated ? <div>
                            <b>Engagement Values Updated At:</b> {engagementLastUpdated.toDateString()
                            }</div> : <div></div>
                    }
                </div>
            </Modal>
        </div>
    );
};