import { Select, Table, DatePicker } from "antd"
import { useEffect, useState } from "react"
import { useGetChannelOptionsQuery } from "../../redux/api/adsReporting";
import { useSelector } from "react-redux";
import PlotContainer from "../../containers/PlotContainer";
import TableContainer from "../../containers/TableContainer";
import {
    useGetOverallMediaSpendBreakdownQuery, useGetComparisonChartQuery, 
    useGetOverallMediaComparisonTableQuery
 } from "../../redux/api/adsReporting";
import DateGranularityDropdown from "../UI/DateGranularityDropdown";
import '../../containers/containerStyles.css'
import dayjs from "dayjs";

export default function OverallMedia() {

    const mediaBreakdownLevelOptions = [
        {label: 'Platform', value: 'platform'},
        {label: 'Channel', value: 'channel'}
    ];

    const metricsDropdownOptions = [
        { label: 'Impressions', value: 'impressions' },
        { label: 'Clicks', value: 'clicks' },
        { label: 'Spend', value: 'spend' },
        { label: 'Conversions', value: 'conversions' },
        { label: 'CPC', value: 'cpc' },
        { label: 'CPM', value: 'cpm' },
        { label: 'CTR', value: 'ctr' },
        { label: 'CVR', value: 'cvr' },
        { label: 'No Selection', value: null }
    ];

    const [selectedMediaBreakdownLevel, setSelectedMediaBreakdownLevel] = useState('platform');
    const [selectedMetric1, setSelectedMetric1] = useState('conversions');
    const [selectedMetric2, setSelectedMetric2] = useState(null);

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);
    const selectedStartDate = useSelector((state) => state.genericReporting.startDate);
    const selectedEndDate = useSelector((state) => state.genericReporting.endDate);
    const granularity = useSelector((state) => state.genericReporting.dateGranularity);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [compareStartDateStr, setCompareStartDateStr] = useState(null);
    const [compareEndDateStr, setCompareEndDateStr] = useState(null);

    const channelOptions = useGetChannelOptionsQuery(
        {
            client: selectedClient,
            breakdownLevel: selectedMediaBreakdownLevel,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            granularity: granularity

        }
    );

    useEffect(() => {
        setSelectedChannels(channelOptions.data);
    }, [channelOptions]);

    useEffect(() => {
        const startDate = dayjs(selectedStartDate);
        const endDate = dayjs(selectedEndDate);

        setCompareStartDateStr(startDate.subtract(32, 'days').format('YYYY-MM-DD'));
        setCompareEndDateStr(endDate.subtract(31, 'days').format('YYYY-MM-DD'));

    }, [selectedStartDate, selectedEndDate]);

    return (
        <div style={{
            'width': '95vw',
            'marginLeft': '1vw',
        }}>
            <div style={
                {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    width: '60vw',
                    margin: 'auto'
                }
            }
            className='flexible-container'
            >
            <div style={{
                    'marginRight': '2vw'
            }}>
                <h3>Select a Date granularity</h3>
                    <DateGranularityDropdown />
            </div>
            <div>
                <h3>Select a Breakdown Level</h3>
                    <Select
                    style={{
                        width: '8vw'
                    }}
                    options={mediaBreakdownLevelOptions}
                    value={selectedMediaBreakdownLevel}
                    onChange={(value) => setSelectedMediaBreakdownLevel(value)} />
            </div>
            <div style={{
                'marginLeft': '2vw'
            }}>
                    <h3>Select Channels</h3>
                    {channelOptions.data && channelOptions.data.length > 0 &&
                        <Select
                            style={{
                                width: '30vw'
                            }}
                            options={
                                channelOptions.data.map(
                                    (channel) => {
                                        return {
                                            label: channel,
                                            value: channel
                                        }
                                    }
                                )
                            }
                            value={selectedChannels}
                            onChange={(value) => setSelectedChannels(value)}
                            mode="multiple"
                        ></Select>
                    }
            </div>
            </div>
            <div className='full-width-container'>
            <PlotContainer
                useApi={useGetOverallMediaSpendBreakdownQuery}
                useApiArgs={{
                    client: selectedClient,
                    startDate: selectedStartDate,
                    endDate: selectedEndDate,
                    granularity: granularity,
                    breakdownLevel: selectedMediaBreakdownLevel,
                    channels: selectedChannels,
                    selectedChannels: selectedChannels
                }}
                />
            </div>
            <div className='full-width-container' style={{
                'marginBottom': '0',
                // make the bottom corners non round
                'borderBottomLeftRadius': '0',
                'borderBottomRightRadius': '0',
                'height': '5vh',
                'paddingTop': '2vh'
            }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                margin: 'auto',
                width: '60vw',
                
            }}>
            <Select options={metricsDropdownOptions} style={{
                width: '10vw'
                    }} value={selectedMetric1} onChange={(value) => {
                setSelectedMetric1(value);
            }} />
            <Select options={metricsDropdownOptions} style={{
                width: '10vw'
                }} value={selectedMetric2} onChange={(value) => setSelectedMetric2(value)} />
                </div>
            </div>
            <div className='full-width-container' style={{
                'marginTop': '0',
                // make the top corners non round
                'borderTopLeftRadius': '0',
                'borderTopRightRadius': '0'
            }}>
                <PlotContainer
                    useApi={useGetComparisonChartQuery}
                    useApiArgs={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: granularity,
                        breakdownLevel: selectedMediaBreakdownLevel,
                        channels: selectedChannels,
                        selectedChannels: selectedChannels,
                        metricOne: selectedMetric1,
                        metricTwo: selectedMetric2
                    }}
                />
            </div>
            <div>
                <h3>
                    Compare Date Range
                </h3>
                <DatePicker.RangePicker
                    value={
                        [dayjs(compareStartDateStr), dayjs(compareEndDateStr)]
                    }
                    onChange={(dates) => {
                        setCompareStartDateStr(dates[0].format('YYYY-MM-DD'));
                        setCompareEndDateStr(dates[1].format('YYYY-MM-DD'));
                    }}
                />
                <TableContainer
                    useApi={useGetOverallMediaComparisonTableQuery}
                    apiParams={{
                        client: selectedClient,
                        startDate: selectedStartDate,
                        endDate: selectedEndDate,
                        granularity: granularity,
                        breakdownLevel: selectedMediaBreakdownLevel,
                        channels: selectedChannels,
                        selectedChannels: selectedChannels,
                        compareStartDate: compareStartDateStr,
                        compareEndDate: compareEndDateStr
                    }}
                    tooltipTitle='This table shows the comparison of the selected metrics for the selected channels.'
                />
            </div>
        </div>
    )
}