import React, { useEffect } from 'react';
import { Select, Tabs, Input } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import ExecutiveSummaryBoxMetrics from '../../containers/ExecutiveSummary/ExecutiveSummaryBoxMetrics';
import ExecutiveSummaryCharts from '../../containers/ExecutiveSummary/ExecutiveSummaryCharts';
import DateGranularityDropdown from '../UI/DateGranularityDropdown';
import { useState } from 'react';
import TooltipContainer from '../UI/TooltipContainer';
import AmazonExecutiveSummaryCharts from '../../containers/ExecutiveSummary/AmazonExecutiveSummaryCharts';
import { useGetAllTabsUserHasAccessToQuery } from '../../redux/api/user';
import { setRollingWindow } from '../../redux/store/genericReportingSlice';
import { useDispatch, useSelector } from 'react-redux';

function ExecutiveSummaryPage() {

    const dispatch = useDispatch();

    const allowedTabs = useGetAllTabsUserHasAccessToQuery();
    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);

    const [tabs, setTabs] = useState([
    {
        label: 'DTC',
        key: 'DTC',
            children: [
            <div>
            <ExecutiveSummaryBoxMetrics></ExecutiveSummaryBoxMetrics>
            <ExecutiveSummaryCharts></ExecutiveSummaryCharts>
        </div>]
    }
    ]);

    useEffect(() => {

        if (allowedTabs.isSuccess) {
            for (var i = 0; i <= allowedTabs.data.length; i++) {
                if (allowedTabs.data[i] == 'amazon' && !tabs.some((tab) => tab.key === 'Amazon')) {
                    const tabToAdd = {
                        label: 'Amazon',
                        key: 'Amazon',
                        children: [
                            <div>
                                <ExecutiveSummaryBoxMetrics isAmazon={true}></ExecutiveSummaryBoxMetrics>
                                <AmazonExecutiveSummaryCharts></AmazonExecutiveSummaryCharts>
                            </div>
                        ]
                    };
                    setTabs([...tabs, tabToAdd]);
                }
                };
            }

    }, [allowedTabs]);

    return (
        <div style={{
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'width': '98vw',
            'alignSelf': 'center',
            'alignItems': 'center',
        }}>
            <GenericReportingDropdowns
                includeDateRange={true}
                extraDropdowns={[
                    <div className='dark-dropdown-container'>
                        <TooltipContainer style={{
                            'alignSelf': 'flex-end',
                            'margin': '0',
                            'width': '0.8vw',
                            'height': '1.0vh',
                        }} invertColor={true} title='Filter By Date Granularity'></TooltipContainer>
                        <h3 style={{
                            'color': 'white'
                        }}>Filter By Date Granularity</h3>
                        <DateGranularityDropdown />
                    </div>,
                    <div className='dark-dropdown-container'>
                        <TooltipContainer style={{
                            'alignSelf': 'flex-end',
                            'margin': '0',
                            'width': '0.8vw',
                            'height': '1.0vh',
                        }} invertColor={true} title='Change the Rolling Window (Days)'></TooltipContainer>
                        <h3 style={{
                            'color': 'white'
                        }}>Change the Rolling Window (days)</h3>
                        <Input type='number' style={{
                            'width': '200px',
                            'marginLeft': '0.2vw'
                        }} value={rollingWindow} onChange={(e) => {
                            dispatch(setRollingWindow(e.target.value));
                        }} />
                    </div>
                ]}
            ></GenericReportingDropdowns>
            <Tabs style={{
                'width': '95vw'

            }} items={tabs} ></Tabs>
        </div>
    );
}

export default ExecutiveSummaryPage;