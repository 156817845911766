import React from 'react';
import { Form, Input, Button, Select, notification, Progress, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';
import {
    useGetCampaignsQuery,
    useGetAdSetsQuery,
    useGetAdsQuery,
    useGetNewGoogleSheetMutation,
    useUploadBulkUploadJobConfigMutation,
    useGetBulkUploadJobConfigQuery,
    useRunBulkUploadJobMutation
} from '../../redux/api/bulkUploader';
import '../containerStyles.css';

const JobConfig = () => {

    const [form] = useForm();

    const [api, contextHolder] = notification.useNotification();

    const [campaignDropdownOptions, setCampaignDropdownOptions] = React.useState([]);
    const [selectedCampaign, setSelectedCampaign] = React.useState(null);
    const [isBulkUploadJobRunning, setIsBulkUploadJobRunning] = React.useState(false);
    const [progressPercent, setProgressPercent] = React.useState(0);

    const [adsetDropdownOptions, setAdsetDropdownOptions] = React.useState([]);
    const [selectedAdset, setSelectedAdset] = React.useState(null);

    const [adDropdownOptions, setAdDropdownOptions] = React.useState([]);
    const [selectedAd, setSelectedAd] = React.useState(null);

    const [googleSheetUrl, setGoogleSheetUrl] = React.useState(null);

    const selectedAccount = useSelector((state) => state.genericReporting.bulkUploaderSelectedClientId);
    const selectedJobTitle = useSelector((state) => state.genericReporting.bulkUploaderSelectedJobName);
    const facebookApiToken = useSelector((state) => state.genericReporting.bulkUploaderFacebookAccessToken);
    const googleSheetsToken = useSelector((state) => state.genericReporting.bulkUploaderAccessToken);

    const { data: campaigns, isFetching: isCampaignsFetching, isError: isCampaignsError, status: campaignStatus,
        refetch: refetchCampaigns
     } = useGetCampaignsQuery({
        fbApiToken: facebookApiToken,
        clientId: selectedAccount
    });
    const { data: adSets, isFetching: isAdSetsFetching, isError: isAdSetsError, status: adSetStatus,
        refetch: refetchAdSets
     } = useGetAdSetsQuery({
        fbApiToken: facebookApiToken,
        campaignId: selectedCampaign
    });

    const { data: ads, isFetching: isAdsFetching, isError: isAdsError, status: adsStatus,
        refetch: refetchAds
     } = useGetAdsQuery({
        fbApiToken: facebookApiToken,
        adSetId: selectedAdset
    });

    const { data: configData } = useGetBulkUploadJobConfigQuery({
        clientId: selectedAccount,
        jobTitle: selectedJobTitle
    });

    const [getNewGoogleSheet, { data: googleSheetUrlData, status: sheetStatus, isError: isSheetError }] = useGetNewGoogleSheetMutation();

    const [runBulkUploadJob, {
        data: runBulkUploadJobData,
        error: runBulkUploadJobError,
        isLoading: runBulkUploadJobIsLoading,
        isSuccess: runBulkUploadJobIsSuccess
    }] = useRunBulkUploadJobMutation();

    const [uploadBulkUploadJobConfig, {
        data: uploadBulkUploadJobConfigData,
        error: uploadBulkUploadJobConfigError,
        isLoading: uploadBulkUploadJobConfigIsLoading,
        isSuccess: uploadBulkUploadJobConfigIsSuccess
    }] = useUploadBulkUploadJobConfigMutation();

    function saveJobConfig() {
        if (!selectedCampaign || !selectedAdset || !selectedAd || !googleSheetUrl) {
            api.error({
                message: 'Error Saving Job Configuration',
                description: 'Please fill in all fields before saving the job configuration.'
            });
            return;
        }
        else {
            uploadBulkUploadJobConfig({
                accountId: selectedAccount,
                jobTitle: selectedJobTitle,
                campaignId: selectedCampaign,
                adSetId: selectedAdset,
                adId: selectedAd,
                googleSheetUrl: googleSheetUrl
            });
        }
    }

    function saveAndRunJob() {

        if (!selectedCampaign || !selectedAdset || !selectedAd || !googleSheetUrl) {
            api.error({
                message: 'Error Saving Job Configuration',
                description: 'Please fill in all fields before running the job.'
            });
            return;
        }
        else {
            setIsBulkUploadJobRunning(true);

            saveJobConfig();

            api.info({
                message: 'Starting Job',
                description: 'The job is starting, this may take a few minutes. You will be notified when the job is finished.'
            });

            // make the progress percent get 1 higher each 2 seconds
            const interval = setInterval(() => {
                setProgressPercent((oldProgress) => {
                    if (oldProgress >= 99) {
                        clearInterval(interval);
                        return 99;
                    }
                    return oldProgress + 1;
                });
            }, 3000);

            // run job
            runBulkUploadJob({
                clientId: selectedAccount,
                jobTitle: selectedJobTitle,
                fbApiToken: facebookApiToken,
                googleSheetsToken: googleSheetsToken
            });
        }
    }

    React.useEffect(() => {
        if (configData) {

            

            setSelectedCampaign(configData.campaign_id);
            setSelectedAdset(configData.ad_set_id);
            setSelectedAd(configData.ad_id);
            setGoogleSheetUrl(configData.google_sheet_url);
        }
    }, [configData]);

    React.useEffect(() => {
        if (runBulkUploadJobIsSuccess) {
            api.success({
                message: 'Job Finished',
                description: 'The job has been finished, check the google sheet for details.'
            });
            setProgressPercent(100);

            // wait for a second then set this to false and set the percent to 0
            setTimeout(() => {
                setIsBulkUploadJobRunning(false);
                setProgressPercent(0);
            }, 2000);
            
        } else if (runBulkUploadJobError) {
            api.error({
                message: 'Error Starting Job',
                description: 'There was an error starting the job. Please refresh auth tokens, check the config and try again.'
            });
            setProgressPercent(100);

            // wait for a second then set this to false and set the percent to 0
            setTimeout(() => {
                setIsBulkUploadJobRunning(false);
                setProgressPercent(0);
            }, 2000);
        }
    }, [runBulkUploadJobIsSuccess, runBulkUploadJobError, runBulkUploadJobData]);

    // show notification on success
    React.useEffect(() => {
        if (uploadBulkUploadJobConfigIsSuccess) {
            api.success({
                message: 'Job Configuration Saved',
                description: 'The job configuration has been saved successfully.'
            });

            // show notification on error
        } else if (uploadBulkUploadJobConfigError) {
            api.error({
                message: 'Error Saving Job Configuration',
                description: 'There was an error saving the job configuration. Please try again.'
            });
        }
    }
        , [uploadBulkUploadJobConfigIsSuccess, uploadBulkUploadJobConfigError, uploadBulkUploadJobConfigData]);

    React.useEffect(() => {
        if (campaigns && campaigns.length > 0) {
            setCampaignDropdownOptions(campaigns.map((campaign) => ({
                label: <div>
                    <p>{campaign.name}</p>
                    <hr></hr>
                    <>{campaign.id}</>
                </div>,
                value: campaign.id
            })));
        }
    }, [campaigns]);

    React.useEffect(() => {
        if (adSets && adSets.length > 0) {
            setAdsetDropdownOptions(adSets.map((adSet) => ({
                label: <div>
                    <p>{adSet.name}</p>
                    <hr></hr>
                    <>{adSet.id}</>
                </div>,
                value: adSet.id
            })));
        }
    }, [adSets]);

    React.useEffect(() => {
        if (ads && ads.length > 0) {
            setAdDropdownOptions(ads.map((ad) => ({
                label: <div>
                    <p>{ad.name}</p>
                    <hr></hr>
                    <>{ad.id}</>
                    </div>,
                value: ad.id
            })
            ));
        }
    }, [ads]);

    React.useEffect(() => {

        setGoogleSheetUrl(googleSheetUrlData);
    }
        ,[googleSheetUrlData]);

    React.useEffect(() => {
        if (isCampaignsError) {
            api.error({
                message: 'Error Fetching Campaigns',
                description: 'There was an error fetching campaigns. Please refresh the page and try again.'
            });
        }
        if (isAdSetsError) {
            api.error({
                message: 'Error Fetching Adsets',
                description: 'There was an error fetching adsets. Please refresh the page and try again.'
            });
        }
        if (isAdsError) {
            api.error({
                message: 'Error Fetching Ads',
                description: 'There was an error fetching ads. Please refresh the page and try again.'
            });
        }
        if (isSheetError) {
            api.error({
                message: 'Error Fetching Google Sheet',
                description: 'There was an error fetching the google sheet. Please refresh the page and try again.'
            });
        }

    }, [isCampaignsError, isAdSetsError, isAdsError, isSheetError]);

    if (selectedJobTitle && facebookApiToken && googleSheetsToken) {
        return (
            <div className='flexible-container'>
                <h3>Configure Selected Job</h3>
                <Form form={form} layout="vertical">
                    <Form.Item label="Campaign">
                        {campaignStatus !== 'fulfilled' ? <Spin></Spin> : <Select style={{
                            'height': '12vh'
                        }} showSearch={true} placeholder="Select a campaign" value={selectedCampaign} onChange={(value) => setSelectedCampaign(value)} options={campaignDropdownOptions} />}
                        {isCampaignsError ? <p>There was an error fetching campaigns. Press the button to retry  <Button onClick={
                            async () => {
                                await refetchCampaigns({ fbApiToken: facebookApiToken, clientId: selectedAccount });
                            }
                        } type="primary">Retry</Button> </p> : null}
                    </Form.Item>
                    <Form.Item label="Adset">
                        {adSetStatus !== 'fulfilled' ? <Spin></Spin> :
                            <Select style={{
                                'height': '12vh'
                            }} showSearch={true} placeholder="Select an adset" value={selectedAdset} onChange={(value) => setSelectedAdset(value)} options={adsetDropdownOptions} />}
                        {isAdSetsError ? <p>There was an error fetching adsets. Press the button to retry  <Button onClick={
                            async () => {
                                await refetchAdSets({ fbApiToken: facebookApiToken, campaignId: selectedCampaign });
                            }
                        } type="primary">Retry</Button> </p> : null}
                    </Form.Item>
                    <Form.Item label="Ad">
                        <p>Select an ad to use as a template for the rest of the ads.</p>
                        {adsStatus !== 'fulfilled' ? <Spin></Spin> :
                            <Select style={{
                                'height': '12vh'
                            }} showSearch={true} placeholder="Select an ad" value={selectedAd} onChange={(value) => setSelectedAd(value)} options={adDropdownOptions} />}
                        {isAdsError ? <p>There was an error fetching ads. Press the button to retry  <Button onClick={
                            async () => {
                                await refetchAds({ fbApiToken: facebookApiToken, adSetId: selectedAdset });
                            }
                        } type="primary">Retry</Button> </p> : null}
                    </Form.Item>
                    <Form.Item label="Google Sheet URL">
                        {sheetStatus !== 'fulfilled' && sheetStatus !== 'uninitialized' ? <Spin></Spin> :
                            <Input placeholder="Enter the Google Sheet URL" value={googleSheetUrl} onChange={(event) => {
                                setGoogleSheetUrl(event.target.value);
                            }} />
                        }
                        <br></br>
                        <Button onClick={
                            async () => {
                                await getNewGoogleSheet({ googleSheetsToken: googleSheetsToken, fbApiToken: facebookApiToken });
                            }
                        } type="primary" style={{ marginTop: '10px' }}>Create New Google Sheet</Button>
                    </Form.Item>
                    <hr></hr>
                    {isBulkUploadJobRunning ? <div>
                        <Progress percent={progressPercent} status="active" />
                        <p>Job is running, please wait...</p>
                    </div> : <>
                        <Button
                            style={{
                                marginRight: '1vw'
                            }}
                            type="primary"
                            onClick={saveJobConfig}
                        >Save Job Configuration</Button>
                        <Button onClick={saveAndRunJob} type="primary">Save And Run Bulk Upload Job</Button>
                    </>
                    }
                </Form>
                {contextHolder}
            </div>
        );
    }
    else {
        return (
            <div className='flexible-container'>
                <h3>No Job Selected</h3>
                <p>Please select a job from the top panel and authorize to configure.</p>
            </div>
        );
    }
};


export default JobConfig;