import React, { useEffect } from 'react';
import { Select, Spin, Button, Modal, Input } from 'antd';
import {
    useDeleteUploadJobMutation,
    useGetBulkUploaderClientsQuery,
    useCreateUploadJobMutation,
    useGetUploadJobsQuery } from '../../redux/api/bulkUploader';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setBulkUploaderSelectedClientId, setBulkUploaderSelectedJobName } from '../../redux/store/genericReportingSlice';

const TopConfig = () => {

    const [clientDropdownOptions, setClientDropdownOptions] = React.useState([]);
    const [isModalVisible, setIsModalVisible] = React.useState(false);
    const [toCreateUploadJobTitle, setToCreateUploadJobTitle] = React.useState(null);
    const [uploadJobsDropdownOptions, setUploadJobsDropdownOptions] = React.useState([]);

    const selectedAccount = useSelector((state) => state.genericReporting.bulkUploaderSelectedClientId);
    const selectedJobTitle = useSelector((state) => state.genericReporting.bulkUploaderSelectedJobName);

    const dispatch = useDispatch();

    const facebookApiToken = useSelector((state) => state.genericReporting.bulkUploaderFacebookAccessToken);

    const [createUploadJob, { data: createUploadJobData, isFetching: isCreateUploadJobFetching }] = useCreateUploadJobMutation();
    const [deleteUploadJob, { data: deleteUploadJobData, isFetching: isDeleteUploadJobFetching }] = useDeleteUploadJobMutation();

    const { data: clientsData, isFetching: isClientsDataFetching } = useGetBulkUploaderClientsQuery(
        {
            fbApiToken: facebookApiToken
        }
    );

    const { data: uploadJobsData, isFetching: isUploadJobsDataFetching } = useGetUploadJobsQuery(
        {
            clientId: selectedAccount
        }
    );

    function onModalSubmit() {

        createUploadJob({
            clientId: selectedAccount,
            jobTitle: toCreateUploadJobTitle
        });

        setIsModalVisible(false);
        setToCreateUploadJobTitle(null);
        
    }

    useEffect(() => {
        if (clientsData) {
            clientsData.map((client) => {
                setClientDropdownOptions((prev) => {
                    return [...prev, {
                        'label': <div>
                            <h4>Account: {client.name}</h4>
                            <hr></hr>
                            <h5>Id: {client.account_id}</h5>
                        </div>,
                        'value': client.account_id
                    }]
                }
                )
            }
            )
            
            }
    }, [clientsData]);


    useEffect(() => {

        if (!uploadJobsData) {
            return
        }
        
        const uploadJobDropdownValues = uploadJobsData.map((job) => {
            return {
                'label': job.job_title,
                'value': job.job_title
            }
        }
        )
    
            

        setUploadJobsDropdownOptions(uploadJobDropdownValues);
    },
        [uploadJobsData]);

    return (
        <div style={{
            outline: '1px solid black',
            padding: '10px',
            borderRadius: '10px',
            flexDirection: 'row',
            display: 'flex',
            justifyContent: 'space-around',
            width: '90vw',
        }}>     
            <div>
                <h3>Please Select an Account</h3>
                {isClientsDataFetching ? <Spin></Spin> : <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={
                        (input, option) =>
                            option.label.props.children[0].props.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    options={clientDropdownOptions}
                    style={{ width: '16vw', height: '14vh' }}
                    onChange={(value) => {
                        dispatch(setBulkUploaderSelectedClientId(value));
                    }}
                />}
            </div>
            <div>
                <h3>Please Select a Bulk Upload Job</h3>
                {isUploadJobsDataFetching ? <Spin></Spin> : <Select options={
                    uploadJobsDropdownOptions
                } onChange={(value) => {
                    dispatch(setBulkUploaderSelectedJobName(value));
                }} style={{ width: '16vw', height: '14vh' }} />}
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '16vw',
            }}>
                <Button onClick={() => {
                    setIsModalVisible(true)
                }}>Create A Bulk Upload Job</Button>
                <br></br>
                <Button onClick={() => {
                    deleteUploadJob({
                        clientId: selectedAccount,
                        jobTitle: selectedJobTitle
                    })
                }}>Delete Selected</Button>
            </div>
            <Modal open={isModalVisible} onCancel={() => {setIsModalVisible(false)}} okText={'Submit'} onOk={onModalSubmit}>
                <h3>Account id: {selectedAccount}</h3>
                <h3>Upload Job Title: </h3>
                <Input value={toCreateUploadJobTitle} onChange={(value) => {
                    setToCreateUploadJobTitle(value.target.value)
                }}></Input>
            </Modal>
        </div>
    );
};

export default TopConfig;