import React from 'react';
import { Tabs } from 'antd';
import { GenericReportingDropdowns } from '../../containers/GenericReportingDropdowns';
import InfluencerContentPage from '../../containers/Influencer/InfluencerContentPage';
import InfluencerCharts from '../../containers/Influencer/InfluencerCharts';
import DateGranularityDropdown from '../UI/DateGranularityDropdown';
import TooltipContainer from '../UI/TooltipContainer';
import { Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setRollingWindow, setIsRolling } from '../../redux/store/genericReportingSlice';
import IsRollingSwitch from '../UI/IsRollingSwitch';
import ExecutiveSummaryBoxMetrics from '../../containers/ExecutiveSummary/ExecutiveSummaryBoxMetrics';
import InfluencerOutreachPage from '../../containers/Influencer/InfluencerOutreachPage';

const Influencer = () => {

    const tabs = [
        {
            label: 'Influencer Media',
            key: 'Influencer Media',
            children: [<InfluencerContentPage></InfluencerContentPage>]
        },
        {
            label: 'Creative Summary',
            key: 'Creative Summary',
            children: [
                <ExecutiveSummaryBoxMetrics isInfluencer={true}></ExecutiveSummaryBoxMetrics>,
                <InfluencerCharts></InfluencerCharts>
            ]
        },
        {
            label: 'Outreach Summary',
            key: 'Outreach Summary',
            children: [
            <InfluencerOutreachPage></InfluencerOutreachPage>
            ]
        }
    ]

    const [activeTabKey, setActiveTabKey] = React.useState(tabs[0].key);

    const rollingWindow = useSelector((state) => state.genericReporting.rollingWindow);
    const isRolling = useSelector((state) => state.genericReporting.IsRolling);

    const dispatch = useDispatch();

    return (
        <div style={{
            'flexDirection': 'column',
            'justifyContent': 'center',
            'alignSelf': 'center',
            'alignItems': 'center',
        }}>
            <GenericReportingDropdowns includeDateRange={activeTabKey !== 'Influencer Media'} extraDropdowns={
                activeTabKey !== 'Influencer Media' ? [
                        <div className='dark-dropdown-container'>
                            <TooltipContainer style={{
                                'alignSelf': 'flex-end',
                                'margin': '0',
                                'width': '0.8vw',
                                'height': '1.0vh',
                            }} invertColor={true} title='Filter By Date Granularity'></TooltipContainer>
                            <h3 style={{
                                'color': 'white'
                            }}>Filter By Date Granularity</h3>
                            <DateGranularityDropdown />
                        </div>,
                        <div className='dark-dropdown-container'>
                            <TooltipContainer style={{
                                'alignSelf': 'flex-end',
                                'margin': '0',
                                'width': '0.8vw',
                                'height': '1.0vh',
                            }} invertColor={true} title='ToggleW Rolling Window'></TooltipContainer>
                            <h3 style={{
                                'color': 'white'
                            }}>Toggle Rolling Window</h3>
                            <IsRollingSwitch style={{
                                'backgroundColor': '#ECE6D9',
                                'borderRadius': '10px',
                                'display': 'flex',
                                'justifyContent': 'center'
                            }} switchStyle={{ 'width': '100%' }} isRolling={isRolling} setIsRolling={(checked) => {
                                dispatch(setIsRolling(checked));
                            }} />
                        </div>,
                        <div className='dark-dropdown-container'>
                            <TooltipContainer style={{
                                'alignSelf': 'flex-end',
                                'margin': '0',
                                'width': '0.8vw',
                                'height': '1.0vh',
                            }} invertColor={true} title='Change the Rolling Window (Days)'></TooltipContainer>
                            <h3 style={{
                                'color': 'white'
                            }}>Change the Rolling Window (days)</h3>
                            <Input type='number' style={{
                                'width': '200px',
                                'marginLeft': '0.2vw'
                            }} value={rollingWindow} onChange={(e) => {
                                dispatch(setRollingWindow(e.target.value));
                            }} />
                        </div>
                    ] : []
            }></GenericReportingDropdowns>
            <Tabs 
                items={tabs} 
                activeKey={activeTabKey} 
                onChange={setActiveTabKey}
            ></Tabs>
        </div>
    );
};

export default Influencer;