import React, { useEffect } from 'react';
import { Form, Select, Input, Button, DatePicker, Checkbox, Radio } from 'antd';
import {
    useGetCompassConfigOptionsQuery,
    useConfigureCompassProjectMutation,
    useGetCompassProjectConfigQuery
} from '../../redux/api/compassProject';
import { useSelector } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';

const CompassDataConfig = () => {

    const [form] = Form.useForm();

    const selectedProject = useSelector((state) => state.compass.selectedProject);
    const [inputColumns, setInputColumns] = React.useState([]);
    const [outputColumns, setOutputColumns] = React.useState([]);
    const [compassColumns, setCompassColumns] = React.useState([]);
    const [columnDefs, setColumnDefs] = React.useState([]);
    const [rowData, setRowData] = React.useState([]);

    const { data: configOptionsData } = useGetCompassConfigOptionsQuery(
        {
            projectId: selectedProject.project_id
        }
    );

    const { data: compassProjectData } = useGetCompassProjectConfigQuery(
        {
            projectId: selectedProject.project_id
        }
    );

    const [configureCompassProject] = useConfigureCompassProjectMutation();

    const dateFormatOptions = [
        {
            'label': '%Y-%m-%d', 'value': '%Y-%m-%d'
        },
        {
            'label': '%Y/%m/%d', 'value': '%Y/%m/%d'
        },
        {
            'label': '%m/%d/%Y', 'value': '%m/%d/%Y'
        },
        {
            'label': '%d/%m/%Y', 'value': '%d/%m/%Y'
        },
        {
            'label': '%Y-%d-%m', 'value': '%Y-%d-%m'
        }

    ]

    useEffect(() => {
        if (configOptionsData) {
            setInputColumns(configOptionsData.input_columns);
            setOutputColumns(configOptionsData.output_columns);
            setCompassColumns(configOptionsData.compass_columns);
        }
    }, [configOptionsData]);

    useEffect(() => {
        if (compassProjectData) {
            form.setFieldsValue({
                'attr_column': compassProjectData.attr_column,
                'compass_column': compassProjectData.compass_column,
                'spend_column': compassProjectData.spend_column,
                'input_date_column': compassProjectData.input_date_column,
                'input_date_format': compassProjectData.input_date_format,
                'output_date_column': compassProjectData.output_date_column,
                'output_date_format': compassProjectData.output_date_format
            });

            setColumnDefs(compassProjectData.matching_cols.column_defs);
            setRowData(compassProjectData.matching_cols.row_data);

        }
    }, [compassProjectData]);

    function onFormSubmit(values) {
        configureCompassProject({
            'projectId': selectedProject.project_id,
            attr_column: values.attr_column,
            compass_column: values.compass_column,
            spend_column: values.spend_column,
            input_date_column: values.input_date_column,
            input_date_format: values.input_date_format,
            output_date_column: values.output_date_column,
            output_date_format: values.output_date_format
        });
    }

    return (
        <div>
            <h1>Compass Data Configuration</h1>
            <Form
                form={form}
                name='compass_data_config'
                onFinish={(values) => {
                    onFormSubmit(values);
                }}
            >
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'justifyContent': 'space-around',
                    'alignItems': 'center',
                    'margin': '2rem'
            }}>
                    <div className='flexible-container' style={{
                        'width': '30%'
                    }}>
                        <h3>Configure the input file</h3>
                        <Form.Item label='Date Format' name='input_date_format'>
                            <Select options={dateFormatOptions} />
                        </Form.Item>
                        <Form.Item label='Select the date column' name='input_date_column'>
                            <Select options={inputColumns.map((column) => {
                                return {
                                    'label': column, 'value': column
                                }
                            })} />
                        </Form.Item>
                    </div>
                    <div className='flexible-container' style={{
                        'width': '30%'
                    }}>
                        <h3>Configure the output file</h3>
                        <Form.Item label='Date Format' name='output_date_format'>
                            <Select options={dateFormatOptions} />
                        </Form.Item>
                        <Form.Item label='Select the date column' name='output_date_column'>
                            <Select options={outputColumns.map((column) => {
                                return {
                                    'label': column, 'value': column
                                }
                            })} />
                        </Form.Item>
                    </div>
                </div>
                <div>
                    <h3>Current Column Configuration</h3>
                    <div
                        className='ag-theme-alpine'
                        style={{
                            height: '24vh',
                            width: '50%',
                            margin: 'auto'
                        }}
                    >
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                        />
                    </div>
                </div>
                <div className='flexible-container'>
                    <h3>Configure the corresponding column names</h3>
                    <Form.Item label='Select the column name from the app' name='compass_column'>
                        <Select options={compassColumns.map((column) => {
                            return {
                                'label': column, 'value': column
                            }
                        })} showSearch='true' />
                    </Form.Item>
                    <Form.Item label='Select the corresponding spend column from the input file' name='spend_column'>
                        <Select options={inputColumns.map((column) => {
                            return {
                                'label': column, 'value': column
                            }
                        })} showSearch='true' />
                    </Form.Item>
                    <Form.Item label='Select the corresponding attribution column from the output file' name='attr_column'>
                        <Select options={outputColumns.map((column) => {
                            return {
                                'label': column, 'value': column
                            }
                        })} showSearch='true' />
                    </Form.Item>
                </div>
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'row',
                    'justifyContent': 'center',
                    'alignItems': 'center',
                    'margin': '2rem'
                }}>
                    <Form.Item>
                        <Button type='primary' htmlType='submit'>
                            Submit
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default CompassDataConfig;