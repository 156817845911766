import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import CompassInputs from './CompassInputs';

import { useSelector } from 'react-redux';
import CompassData from './CompassData';
import { useGetIfUserIsAdminQuery } from '../../redux/api/user';
import CompassProjects from './CompassProjects';
import { setSelectedProject } from '../../redux/store/compassSlice';
import { setStartAndEndDate } from '../../redux/store/genericReportingSlice';
import { useGetActiveProjectQuery, useGetCompassStartEndDatesQuery } from '../../redux/api/compassProject';
import { useDispatch } from 'react-redux';
import FullChannelImpjactNCac from './FullChannelImpjactNCac';
import PaidChannelComparison from './PaidChannelComparison';
import ConsiderationContribution from '../../containers/Compass/ConsiderationContribution';
import CompassDataConfig from './CompassDataConfig';

function CompassTabs() {

    const dispatch = useDispatch();

    const [selectedTab, setSelectedTab] = React.useState('Full Channel Impact & CAC');

    const selectedProject = useSelector((state) => state.compass.selectedProject);
    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const compassStartEndDates = useGetCompassStartEndDatesQuery({
        clientName: selectedClient
    });

    const activeProject = useGetActiveProjectQuery({
        client_name: selectedClient
    });

    // if compassStartEndDates updates, update the start and end dates of the active project
    useEffect(() => {
        if (compassStartEndDates.isSuccess) {
            dispatch(setStartAndEndDate([
               compassStartEndDates.data.start_date, compassStartEndDates.data.end_date
            ]));
        }
    }, [compassStartEndDates]);

    const isUserAdmin = useGetIfUserIsAdminQuery();
    const [tabs, setTabs] = React.useState([{
        label: 'Full Channel Impact & CAC',
        key: 'Full Channel Impact & CAC',
        children: [<FullChannelImpjactNCac />]
    },
    {
        label: 'Paid Channel Comparison',
        key: 'Paid Channel Comparison',
        children: [<PaidChannelComparison />]
    },
    {
        label: 'Consideration Contribution',
        key: 'Consideration Contribution',
        children: [<ConsiderationContribution />]
    }
    ]);


    useEffect(() => {
        if (isUserAdmin.isSuccess) {
            if (isUserAdmin.data) {
                setTabs([
                    {
                        label: 'Manage Projects',
                        key: 'Manage Projects',
                        children: <CompassProjects />
                    },
                    {
                        label: 'Inputs',
                        key: 'Inputs',
                        children: <CompassInputs />
                    },
                    {
                        label: 'Data',
                        key: 'Data',
                        children: <CompassData />
                    },
                    {
                        label: 'Data Configuration',
                        key: 'Data Configuration',
                        children: <CompassDataConfig />
                    },
                    {
                        label: 'Full Channel Impact & CAC',
                        key: 'Full Channel Impact & CAC',
                        children: [<FullChannelImpjactNCac />]
                    },
                    {
                        label: 'Paid Channel Comparison',
                        key: 'Paid Channel Comparison',
                        children: [<PaidChannelComparison />]
                    },
                    {
                        label: 'Consideration Contribution',
                        key: 'Consideration Contribution',
                        children: [<ConsiderationContribution />]
                    }
                ]);
                setSelectedTab('Manage Projects');
            }
            else {
                dispatch(setSelectedProject(activeProject.data));
            }
        }
    }
    , [isUserAdmin, activeProject]);
        

    return (
        <>
            <div style={{
                'width': '95vw',
            }}>
                <Tabs onChange={(value) => {
                    setSelectedTab(value);
                }} activeKey={selectedTab} items={tabs} ></Tabs>
            </div>
        </>
        );
}

export default CompassTabs;