import BulkUploaderGoogleLogin from "./BulkUploaderGoogleLogin"
import BulkUploaderFacebookLogin from "./BulkUploaderFacebookLogin"
import TopConfig from "../../containers/BulkUpload/TopConfig"
import { Collapse } from "antd";
import JobConfig from "../../containers/BulkUpload/JobConfig";

export default function BulkUpload() {

    const collapseItems = [
        {
            'label': 'Log In',
            'children': <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '60vw',
                margin: 'auto'
            }}>
                <BulkUploaderGoogleLogin />
                <BulkUploaderFacebookLogin />
            </div>

        }
    ]

    return (
        <div>
            <h1>Facebook Bulk Upload Tool</h1>
            <p>Log In To get started (you might want to refresh your tokens every now and then as they don't automatically refresh.</p>
            <Collapse items={collapseItems} >
            </Collapse>
            <br></br>
            <TopConfig />
            <br></br>
            <JobConfig />
        </div>
    )
}