import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from './baseQuery';

export const genericReportingApi = createApi({
    reducerPath: 'genericReportingApi',
    baseQuery: baseQuery,
    tagTypes: ['GenericReporting', 'ExecutiveSummary', 'ProductGroupConfig', 'ProductGroups', 'MetricOptions'],
    endpoints: (builder) => ({
        getBoxMetrics: builder.query({
            query: ({
                client,
                startDate,
                endDate,
                compareStartDate,
                compareEndDate,
                isAmazon,
                isInfluencer,
                isOutreach,
                useOldPrefix,
                prefixStr
            }) => (useOldPrefix ? '/executive_summary' + (isAmazon ? '_amazon' : '') + (isInfluencer ? '_influencer' : '') + (isOutreach ? '_outreach' : '') : prefixStr) + '/box_metrics' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&compare_start_date=" + compareStartDate + "&compare_end_date=" + compareEndDate,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewCustomerVsNcpa: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow,
                platform
            }) => '/executive_summary/new_customers_vs_ncpa_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + '&is_rolling=' + isRolling + '&rolling_window=' + rollingWindow + '&platform=' + platform,
            providesTags: ['ExecutiveSummary'],
        }),
        getNewSubsVsCpns: builder.query({
            query: ({
                client,
                granularity,
                startDate,
                endDate,
                isRolling,
                rollingWindow
            }) => '/executive_summary/new_subs_vs_cpns_chart' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
            providesTags: ['ExecutiveSummary'],
        }),
        getRevenueSplitByNewReturningPlot: builder.query({
            query: ({
                client,
                country,
                granularity,
                startDate,
                endDate
            }) => '/executive_summary/revenue_split_by_new_and_returning_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&country=" + country + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        getUniqueShopifyCountries: builder.query({
            query: ({ client }) => '/shopify_unique_countries' + "?client=" + client,
            providesTags: ['ExecutiveSummary'],
        }),
        getLtrLtv: builder.query({
            query: ({ client, isLtv, filterType }) => '/ltr_ltv' + "?client_name=" + client + "&is_ltv=" + isLtv + "&type=" + filterType,
        }),
        getCohortedRetention: builder.query({
            query: ({ client, filterType }) => '/cohorted_retention' + "?client_name=" + client + "&type=" + filterType,
        }),
        getRollingSpend: builder.query({
            query: ({ client, startDate, endDate, granularity, rollingWindow, platform }) => '/executive_summary/rolling_spend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&rolling_window=" + rollingWindow + "&platform=" + platform,
            providesTags: ['ExecutiveSummary'],
        }),
        getAovTrend: builder.query({
            query: ({ client, startDate, endDate, granularity }) => '/executive_summary/aov_trend' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity,
            providesTags: ['ExecutiveSummary'],
        }),
        createProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroupName,
                platform
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroupName + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        listProductGroupConfigs: builder.query({
            query: ({ client, platform }) => '/settings/product_group_configs' + "?client=" + client + "&platform=" + platform,
            providesTags: ['ProductGroupConfig'],
        }),
        activateProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/activate_product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        getActiveProductGroupConfig: builder.query({
            query: ({ client, platform }) => '/settings/active_product_group_config' + "?client=" + client + "&platform=" + platform,
            providesTags: ['ProductGroupConfig'],
        }),
        deleteProductGroupConfig: builder.mutation({
            query: ({
                client,
                configName,
                platform
            }) => ({
                url: '/settings/product_group_config' + "?client=" + client + "&config_name=" + configName + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['ProductGroupConfig'],
        }),
        addAProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
                platform
            }) => ({
                url: '/settings/product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        getProductsTable: builder.query({
            query: ({ client, configName, startDate, endDate, platform }) => '/settings/product_table' + "?client=" + client + "&config_name=" + configName + "&start_date=" + startDate + "&end_date=" + endDate + "&platform=" + platform,
            providesTags: ['ProductGroups'],
        }),
        getProductGroups: builder.query({
            query: ({ client, configName, platform }) => '/settings/product_groups' + "?client=" + client + "&config_name=" + configName + '&platform=' + platform,
            providesTags: ['ProductGroups'],
        }),
        assignToProductGroup: builder.mutation({
            query: ({
                client,
                configName,
                productGroup,
                products,
                platform
            }) => ({
                url: '/settings/assign_to_product_group' + "?client=" + client + "&config_name=" + configName + "&product_group_name=" + productGroup + '&platform=' + platform,
                method: 'PUT',
                body: {
                    products: products
                }
            }),
            invalidatesTags: ['ProductGroups'],
        }),
        getLtrByCategory: builder.query({
            query: ({ clientName, selectedConfig }) => '/ltr_by_category' + "?client_name=" + clientName + "&selected_config=" + selectedConfig,
        }),
        getNewReturningCustomersByCategory: builder.query({
            query({ clientName, selectedConfig, startDate, endDate, granularity, platform }) {
                return '/executive_summary/categorical_new_returning_customers' + "?client=" + clientName + "&product_group_config_name=" + selectedConfig + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&platform=" + platform;
            },
            providesTags: ['ExecutiveSummary'],
        }),
        getNewCustomerVsNewSubsPercentage: builder.query({
            query: ({ client, startDate, endDate, granularity, platform }) => '/executive_summary/new_customer_vs_new_subs_percentage' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&platform=" + platform,
        }),
        getKlaviyoAcquisition: builder.query({
            query: ({
                client,
                startDate,
                endDate
            }) => '/klaviyo/acqusition' + "?client_name=" + client + "&start_date=" + startDate + "&end_date=" + endDate,
        }),
        getBoxMetricOptions: builder.query({
            query: ({ client, platform }) => '/settings/box_metric_options' + "?client=" + client + "&platform=" + platform,
        }),
        setBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber, platform }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber + "&platform=" + platform,
                method: 'PUT',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        deleteBoxMetricOptions: builder.mutation({
            query: ({ client, metricTitle, orderNumber, platform }) => ({
                url: '/settings/box_metric_option' + "?client=" + client + "&metric_title=" + metricTitle + "&order_number=" + orderNumber + "&platform=" + platform,
                method: 'DELETE',
            }),
            invalidatesTags: ['MetricOptions'],
        }),
        getBoxMetricOptionsTable: builder.query({
            query: ({ client, platform }) => '/settings/configured_box_metric_options_table' + "?client=" + client + "&platform=" + platform,
            providesTags: ['MetricOptions'],
        }),
        getIfIsClientOnTikTok: builder.query({
            query: ({ client }) => '/settings/is_client_on_tiktok' + "?client=" + client,
        }),
        getIfIsClientOnBing: builder.query({
            query: ({ client }) => '/settings/is_client_on_bing' + "?client=" + client,
        }),
        getLtvVsCac: builder.query({
            query: ({ client, type }) => {
                return '/ltv_vs_cac' + "?client_name=" + client + "&type=" + type;
            }
        }),
        getAmazonRevenuePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/revenue_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonOrdersPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/orders_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonAovPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/aov_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getAmazonDtcSpendVsSalesPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/dtc_spend_vs_sales_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getExecutiveSummaryAmazonRoasPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/executive_summary_amazon/roas_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getArchiveRowCount: builder.query({
            query: ({ client }) => '/influencer_archive/influencer_posts_count' + "?client=" + client,
        }),
        getInfluencerPosts: builder.query({
            query: ({ client, page, pageSize }) => '/influencer_archive/influencer_posts' + "?client=" + client + "&page=" + page + "&page_size=" + pageSize,
        }),
        getInfluencerContentCountPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/content_count_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getInfluencerEngagementMetricsPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/engagement_metrics_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getReachVsRollingReachPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, rollingWindow }) => '/influencer_archive/reach_vs_rolling_reach_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&rolling_window=" + rollingWindow,
        }),
        getNewVsReturningReachPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/new_vs_returning_reach_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getNewVsReturningContentPlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/new_vs_returning_content_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getReachByCreatorTypePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/reach_by_creator_type_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getContentCountByCreatorTypePlot: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/content_count_by_creator_type_plot' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getArchiveOutreachGraph: builder.query({
            query: ({ client, startDate, endDate, granularity, isRolling, rollingWindow }) => '/influencer_archive/outreach_graph' + "?client=" + client + "&start_date=" + startDate + "&end_date=" + endDate + "&granularity=" + granularity + "&is_rolling=" + isRolling + "&rolling_window=" + rollingWindow,
        }),
        getBulkUploaderClients: builder.query({
            query: () => '/bulk_uploader/clients',
        }),
    }),
});


export const {
    useGetNewCustomerVsNewSubsPercentageQuery,
    useGetBulkUploaderClientsQuery,
    useGetArchiveOutreachGraphQuery,
    useGetContentCountByCreatorTypePlotQuery,
    useGetReachByCreatorTypePlotQuery,
    useGetNewVsReturningContentPlotQuery,
    useGetNewVsReturningReachPlotQuery,
    useGetReachVsRollingReachPlotQuery,
    useGetInfluencerEngagementMetricsPlotQuery,
    useGetInfluencerContentCountPlotQuery,
    useGetInfluencerPostsQuery,
    useGetArchiveRowCountQuery,
    useGetBoxMetricsQuery,
    useGetNewCustomerVsNcpaQuery,
    useGetNewSubsVsCpnsQuery,
    useGetRevenueSplitByNewReturningPlotQuery,
    useGetUniqueShopifyCountriesQuery,
    useGetLtrLtvQuery,
    useGetCohortedRetentionQuery,
    useGetRollingSpendQuery,
    useGetAovTrendQuery,
    useCreateProductGroupConfigMutation,
    useListProductGroupConfigsQuery,
    useActivateProductGroupConfigMutation,
    useGetActiveProductGroupConfigQuery,
    useDeleteProductGroupConfigMutation,
    useAddAProductGroupMutation,
    useGetProductsTableQuery,
    useGetProductGroupsQuery,
    useAssignToProductGroupMutation,
    useGetLtrByCategoryQuery,
    useGetNewReturningCustomersByCategoryQuery,
    useGetKlaviyoAcquisitionQuery,
    useDeleteProductGroupMutation,
    useGetBoxMetricOptionsQuery,
    useSetBoxMetricOptionsMutation,
    useDeleteBoxMetricOptionsMutation,
    useGetBoxMetricOptionsTableQuery,
    useGetIfIsClientOnTikTokQuery,
    useGetIfIsClientOnBingQuery,
    useGetLtvVsCacQuery,
    useGetAmazonRevenuePlotQuery,
    useGetAmazonOrdersPlotQuery,
    useGetAmazonAovPlotQuery,
    useGetAmazonDtcSpendVsSalesPlotQuery,
    useGetExecutiveSummaryAmazonRoasPlotQuery
} = genericReportingApi;

export default genericReportingApi.reducer;