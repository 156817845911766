import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from "./baseQuery";

export const whitelistedMediaComparisonApi = createApi({
    reducerPath: 'whitelistedMediaComparisonApi',
    baseQuery: baseQuery,
    tagTypes: ['WhitelistedMediaComparison'],
    endpoints: (builder) => ({
        getAdUsernamePairs: builder.query({
            query: ({ client, startDate, endDate, platform }) => {
                return {
                    url: `whitelisted_media_comparison/ad_username_pairs`,
                    method: 'GET',
                    params: {
                        'client': client,
                        'start_date': startDate,
                        'end_date': endDate,
                        'platform': platform
                    },
                }
            },
            providesTags: ['WhitelistedMediaComparison']
        }),
        updateAdUsernamePairs: builder.mutation({
            query: ({ client, platform, adUsernamePairs }) => {
                return {
                    url: `whitelisted_media_comparison/ad_username_pairs`,
                    method: 'PUT',
                    body: {
                        'client': client,
                        'platform': platform,
                        'ad_username_pairs': adUsernamePairs
                    },
                }
            },
            invalidatesTags: ['WhitelistedMediaComparison']
        }),
        deleteAdUsernamePairs: builder.mutation({
            query: ({ client, platform, adUsernamePairs }) => {
                return {
                    url: `whitelisted_media_comparison/ad_username_pairs`,
                    method: 'DELETE',
                    body: {
                        'client': client,
                        'platform': platform,
                        'ad_username_pairs': adUsernamePairs
                    },
                }
            },
            invalidatesTags: ['WhitelistedMediaComparison']
        }),
        getTable: builder.query({
            query: ({ client, platform, startDate, endDate, groupBy }) => {
                return {
                    url: `whitelisted_media_comparison/table`,
                    method: 'GET',
                    params: {
                        'client': client,
                        'platform': platform,
                        'start_date': startDate,
                        'end_date': endDate,
                        'group_by': groupBy
                    },
                }
            },
            providesTags: ['WhitelistedMediaComparison']
        }),
    }),
});

export default whitelistedMediaComparisonApi.reducer;

export const {
    useGetTableQuery,
    useGetAdUsernamePairsQuery, 
    useUpdateAdUsernamePairsMutation, 
    useDeleteAdUsernamePairsMutation
 } = whitelistedMediaComparisonApi;