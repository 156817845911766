import React, { useEffect } from 'react';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setBulkUploaderFacebookAccessToken } from '../../redux/store/genericReportingSlice';
import { useGetHowLongFacebookTokenIsValidQuery } from '../../redux/api/bulkUploader';
import { notification } from 'antd';


export default function BulkUploaderFacebookLogin() {

    const dispatch = useDispatch();

    const accessToken = useSelector((state) => state.genericReporting.bulkUploaderFacebookAccessToken);
    const { data: expiryInSeconds } = useGetHowLongFacebookTokenIsValidQuery({
        fbApiToken: accessToken
    }, { skip: !accessToken }
    );
    const [api, contextHolder] = notification.useNotification();

    useEffect(() => {
        if (expiryInSeconds) {
            removeFacebookTokenOnExpiry(expiryInSeconds)
        }
    }, [expiryInSeconds])

    function removeFacebookTokenOnExpiry(expiryInSeconds) {

        if (expiryInSeconds) {
            setTimeout(() => {
                dispatch(
                    setBulkUploaderFacebookAccessToken(null)
                )
                api.info({
                    message: 'Facebook Token Expired, Please Re-Authorize',
                    description: 'Your Facebook token has expired, please re-authorize'
                })
            }, expiryInSeconds * 1000)
        }

    }

    const scopes = [
        'ads_management',
        'ads_read',
        'read_insights',
        'business_management',
        'pages_show_list',
    ]

    const responseFacebook = (response) => {
        const token = response.accessToken;

        

        dispatch(
            setBulkUploaderFacebookAccessToken(token)
        )

        api.success({
            message: 'Facebook Token Authorized',
            description: 'You have successfully authorized the Facebook Token'
        })

        removeFacebookTokenOnExpiry();
    }

    return (
        <div>
            <FacebookLogin
                appId='3812417835691831'
                fields="name,email,picture"
                scope={scopes.join(',')}
                onSuccess={responseFacebook}
                autoLoad={false}
                render={renderProps => (
                    <Button onClick={renderProps.onClick}>Authorize Facebook Ad Uploader</Button>
                )}
            />
            <br></br>
            {accessToken && 'Authorized'}
            {contextHolder}
        </div>
    )
}