import { useGoogleLogin } from "@react-oauth/google";
import { Button } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import { setBulkUploaderAccessToken, removeBulkUploaderAccessToken } from "../../redux/store/genericReportingSlice";
import { notification } from 'antd';


export default function BulkUploaderGoogleLogin() {

    const dispatch = useDispatch();

    const clientId = process.env.REACT_APP_AUTH_CLIENT_ID;
    const validHd = process.env.REACT_APP_AUTH_VALID_HD

    const accessToken = useSelector((state) => state.genericReporting.bulkUploaderAccessToken);
    const [api, contextHolder] = notification.useNotification();

    function success(response) {
        const access_token = response.access_token;

        

        dispatch(
            setBulkUploaderAccessToken(access_token)
        )

        api.success({
            message: 'Google Token Authorized',
            description: 'You have successfully authorized the Google Token'
        })

        const expiresIn = response.expires_in;

        setTimeout(() => {
            expireToken()
        }, (expiresIn - 60) * 1000)

    }

    function expireToken() {
        api.info({
            message: 'Google Token Expired, Please Re-Authorize',
            description: 'Your Google token has expired, please re-authorize'
        })
        dispatch(
            removeBulkUploaderAccessToken()
        )
    }

    const login = useGoogleLogin({
        clientId: clientId,
        scope: "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile openid profile email https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets",
        onSuccess: (response) => success(response),
        onFail: (response) => console.log(response),
        hosted_domain: validHd,
        flow: 'implicit',
    });

    return (
    <div>
            <Button onClick={login}>Authorize Google Sheets/Drive</Button>
            <br></br>
            {accessToken && 'Authorized'}
            {contextHolder}
    </div>
    );
    
}