import {
    useCreateProductGroupConfigMutation,
    useListProductGroupConfigsQuery,
    useActivateProductGroupConfigMutation,
    useGetActiveProductGroupConfigQuery,
    useDeleteProductGroupConfigMutation,
} from '../../redux/api/genericReporting';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Spin, Select, Button, Input, Collapse } from 'antd';


export default function ProductGroupingConfigSelection({
    selectedConfig,
    setSelectedConfig,
    platform = 'Shopify'
}) {

    const selectedClient = useSelector((state) => state.genericReporting.selectedClient);

    const [toCreateConfigName, setToCreateConfigName] = useState('');

    const activeConfig = useGetActiveProductGroupConfigQuery(
        {
            client: selectedClient,
            platform: platform
        }
    );
    const configOptions = useListProductGroupConfigsQuery(
        {
            client: selectedClient,
            platform: platform
        }
    );

    const [createConfig] = useCreateProductGroupConfigMutation();
    const [activateConfig] = useActivateProductGroupConfigMutation();
    const [deleteConfig] = useDeleteProductGroupConfigMutation();

    useEffect(() => {
        if (activeConfig.status === 'fulfilled') {
            setSelectedConfig(activeConfig.data);
        }
        else if (configOptions.status === 'fulfilled') {
            setSelectedConfig(configOptions.data[0]);
        }
    }
        , [activeConfig, configOptions]);

    return (
        <div>
            <h1>Create, Delete, Manage Configs</h1>
            <Collapse defaultActiveKey={['1']} items={[
                {
                    key: '1',
                    label: 'Create, Delete, Manage Configs',
                    children: <div style={{
                        'display': 'flex',
                        'flexDirection': 'row',
                        'justifyContent': 'space-around',
                    }}>
                        <div>
                            <h2>Create a new config</h2>
                            <div>
                                <Input type="text" placeholder="Enter config name" value={toCreateConfigName} onChange={(e) => setToCreateConfigName(e.target.value)} />
                                <Button onClick={() => createConfig({ client: selectedClient, configName: toCreateConfigName, 'platform': platform })}>Create</Button>
                            </div>
                        </div>
                        <div>
                            <h2>Active Config</h2>
                            <div>
                                {activeConfig.status === 'fulfilled' ? activeConfig.data : <Spin />}
                            </div>
                        </div>
                        <div>
                            <h2>Manage Selected Config</h2>
                            <Button onClick={() => activateConfig({ client: selectedClient, configName: selectedConfig, 'platform': platform })} style={{
                                'marginRight': '1vw'
                            }}>Activate</Button>
                            <Button onClick={() => deleteConfig({ client: selectedClient, configName: selectedConfig, 'platform': platform })}>Delete</Button>
                        </div>
                    </div>
                }]} /  >
                
            <div>
                <h1>Select a config</h1>
                <div>
                    {configOptions.status === 'fulfilled' ? <Select style={{
                        width: '50%'
                    }} options={configOptions.data.map(
                        (config) => {
                            return {
                                label: config,
                                value: config
                            }
                        }
                    )} onChange={(value) => setSelectedConfig(value)} value={selectedConfig} /> : <Spin />}
                </div>
            </div>
        </div>
    );
    }